<template>
  <div>
    <div class="d-flex justify-center">
       <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center">
          <img style="width:200px;" src="/media/gift/1103-confetti-flat.gif">
      </div>
    </div>

     <div class="d-flex justify-center">
       <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center">
          <div class="mitr-font" style="font-size: 2rem;">แสกน QR เรียบร้อยแล้ว</div>
        </div>
    </div>

    <div class="d-flex justify-center pt-10">
       <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center">
          <div class="mitr-font" style="font-size: 1.5rem; color: #d3d3d3">{{$route.params.theCode}}</div>
        </div>
    </div>


     <div class="d-flex justify-center pt-20">
       <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center">
           <b-button
                variant="success"
                class="btn  text-uppercase px-9 py-4 ml-8 cols-12 col-sm-12 col-lg-9"
                style="font-family: mitr; font-weight: bold; font-size: 1.5rem;"
                data-wizard-type="action-submit"
                @click="$router.push('/')"
                
              >
                กลับสู่หน้าหลัก
            </b-button>
        </div>
    </div>
    
  </div>
</template>

<script>


import { QrcodeStream } from 'vue-qrcode-reader'
// import { QrcodeStream } from '../../../../src'
import Swal from "sweetalert2";

export default {


  data () {
    return {
      result: '',
      error: ''
    }
  },

  methods: {
    onDecode (result) {
      this.result = result;

      // Swal.fire({
      //           title: "Scan ใช้งานเรียบร้อยแล้ว",
      //           text:this.result,
      //           icon: "success",
      //           confirmButtonClass: "btn btn-secondary"
      // });
      
    },
    watch: {
        result(value){
            // if(value !== null && value !== undefined)
            // {
            //     Swal.fire({
            //         title: "Scan ใช้งานเรียบร้อยแล้ว",
            //         text: "อัพโหลดไฟล์ Excel เรียบร้อยแล้ว",
            //         icon: "success",
            //         confirmButtonClass: "btn btn-secondary"
            //     });
            // }
        }
    },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>

<style >
.wrapper{
  padding-top : 0 !important;
  margin-top : 0 !important;
}
</style>
